@import "~node_modules/ng-zorro-antd/style/entry";
@import "~node_modules/ng-zorro-antd/button/style/entry";
@import "~node_modules/ng-zorro-antd/spin/style/entry";
@import "~node_modules/ng-zorro-antd/input/style/entry";
@import "~node_modules/ng-zorro-antd/icon/style/entry";
@import "~node_modules/ng-zorro-antd/checkbox/style/entry";
@import "~node_modules/ng-zorro-antd/notification/style/entry";
@import "~node_modules/ng-zorro-antd/rate/style/entry";
@import "~node_modules/ng-zorro-antd/slider/style/entry";
@import "~node_modules/ng-zorro-antd/modal/style/entry";
@import "~node_modules/ng-zorro-antd/select/style/entry";
@import "~node_modules/ng-zorro-antd/alert/style/entry";
@import "~node_modules/ng-zorro-antd/carousel/style/entry";
@import "~node_modules/ng-zorro-antd/popover/style/entry";
@import "./themes/dark";

@import "components/index";

:root {
  --uni-light-100: #000000;
  --uni-light-150: #91949b;
  --uni-light-200: #485063;
  --uni-light-250: #313c53;
  --uni-light-400: #283248;
  --uni-light-600: #1e273a;
  --uni-light-750: #222937;
  --uni-light-800: #1b1b1b;

  --uni-contrast-300: #c3c3c3;
  --uni-contrast-500: #ffffff;
  --uni-primary-contrast-500: #ffffff;

  --uni-primary-300: #eb3137;
  --uni-primary-400: #cf191f;
  --uni-primary-800: #a31418;
  --uni-primary-900: #5c0a0d;

  --uni-black-500: #101010;
  --uni-black-400: #15161a;
  --uni-black-100: #212429;
  --uni-grey-400: #495057;
  --uni-grey-300: #acb5bd;
  --uni-grey-200: #dde2e5;
  --uni-grey-100: #f8f9fa;
  --uni-blue-400: #0357a5;
  --uni-blue-200: #01a9f0;
  --uni-blue-100: #469ec3;
  --uni-yellow-400: #db7d00;
  --uni-yellow-200: #f9a200;
  --uni-yellow-100: #f9c057;
  --uni-purple-400: #8400ab;
  --uni-purple-200: #bc00f4;
  --uni-green-400: #017040;
  --uni-green-200: #00c16d;
  --uni-green-100: #44c18a80;
  --uni-red-400: #b72a28;
  --uni-red-200: #f15754;
  --uni-red-100: #f1575480;
}
